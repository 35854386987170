<template>
  <div class="sidebar" :class="this.$store.state.techStore.isSidebarOpened && 'active'">
    <div class="sidebar__logo">
      <logo />
    </div>
    <nav>
      <ul class="sidebar__nav">
        <li class="sidebar__nav-item">
          <router-link to="/cashier/purchase">
            <div class="sidebar__nav-text">
              <icon class="sidebar__icon" name="operations"/>
              Регистрация покупки
            </div>
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="sidebar__purchase">
      <a href="#" class="sidebar__purchase-item" @click="this.$store.commit('techStore/logout')">
        <icon name="logout" class="sidebar__icon header-pop-item-icon-close" />
        Выход
      </a>
    </div>
  </div>
</template>

<script>
import logo from '@/components/logo'
import Icon from '@/components/icons/icon'

export default {
  name: 'sidebar',
  components: {
    logo,
    Icon
  },
  created () {}
}
</script>

<style scoped src="./cashierSidebar.scss" lang="scss"/>
