<template>
  <div v-if="step1" class="step-1">
    <div class="layout-1">
      <div class="purchase-content">
        <h3 class="purchase-title">Введите код скидки (6 цифр) или номер телефона клиента</h3>
        <div class="step-1-input">
          <div class="input-wrapper">
            <div class="input-container" :class="phoneCodeError ? 'input-validate' : ''">
              <input
                class="input"
                v-model="code"
                @input="codeValidating"
              >
              <div v-if="phoneCodeError" class="validate-icon">
                <icon name="validate"/>
              </div>
            </div>
            <div v-if="phoneCodeError" class="error">
              {{ phoneCodeError }}
            </div>
          </div>
        </div>
        <p class="purchase-desc">При вводе номера телефона используйте международный формат.
          Например: +79876543210
          <br><br>
          По номеру телефона доступно только начисление бонусов (списание недоступно)</p>
        <xButton @click="toggleStep2" size="small">
          Отправить
        </xButton>
      </div>
    </div>
  </div>
  <div v-if="step2" class="step-2">
    <div class="step-2-header layout-1">
      <div class="step-2-header-item">
        <div class="step-2-header-desc">
          Клиент
        </div>
        <div class="step-2-header-data">
          {{ clientData.name }}
        </div>
      </div>
      <div class="step-2-header-item">
        <div class="step-2-header-desc">
          На бонусном счёте
        </div>
        <div class="step-2-header-data">
          {{ bonusNumberFormat }}
        </div>
      </div>
      <div class="step-2-header-item">
        <p class="step-2-header-rules">
          Справочно:
          <br>
          Срендяя покупка клиента — <b>{{ sumNumberFormat }} руб.</b>
          <br>
          Предлагайте сопутствующие товары и увеличивайте чек!
        </p>
      </div>
    </div>
    <div class="layout-1">
      <div class="step-2-billing">
        <div class="step-2-billing-item">
          <div class="step-2-billing-text">
            Сумма к оплате
          </div>
          <div class="input-wrapper">
            <div class="input-container" :class="moneyError ? 'input-validate' : ''">
              <input
                class="input"
                v-model="money"
                @input="moneyValidating"
              >
              <div v-if="moneyError" class="validate-icon">
                <icon name="validate"/>
              </div>
            </div>
            <div v-if="moneyError" class="error">
              {{ moneyError }}
            </div>
          </div>
        </div>
        <div class="step-2-billing-item">
          <div class="step-2-billing-text">
            Списание бонусов
          </div>
          <div class="input-wrapper">
            <div
              class="input-container"
              :class="(bonusError ? 'input-validate' : '') + (!clientData.isTypeCode ? ' input-disabled' : '')"
            >
              <input
                class="input"
                :disabled="!clientData.isTypeCode"
                v-model="bonus"
                @input="bonusValidating"
              >
              <div v-if="bonusError" class="validate-icon">
                <icon name="validate"/>
              </div>
            </div>
            <div v-if="bonusError" class="error">
              {{ bonusError }}
            </div>
          </div>
        </div>
        <div v-if="clientData.isTypeCode" class="step-2-billing-desc">
          <span class="step-2-billing-desc-text">
            Доступно к списанию с бонусного счета — {{ bonusCanUseNumberFormat }}
          </span>
          <button @click="spendAllBonuses" class="link">Списать все</button>
        </div>
        <div class="step-2-billing-item">
          <div class="step-2-billing-text">
            К оплате деньгами
          </div>
          <textInput :defaultValue="purchaseCalc" :disabled="true"/>
        </div>
        <div class="step-2-billing-button">
          <xButton @click="toggleStep3" size="fullwidth">
            Провести операцию
          </xButton>
        </div>
      </div>
    </div>
  </div>
  <transition name="purchaseSuccess" :duration="100">
    <div v-if="step3" class="step-3">
      <div class="layout-1">
        <div class="step-3-content">
          <icon name="success" class="step-3-icon" />
          <p class="step-3-thankyou">
            Операция прошла успешно
          </p>
          <a href="" class="link step-3-link">
            Продолжить
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import xButton from '@/components/xButton'
import textInput from '@/components/textInput'

const pluralize = (count, words, formattedCount = false) => {
  const countN = parseInt(count)
  const cases = [2, 0, 1, 1, 1, 2]
  const printCount = formattedCount || count.toString()
  return printCount + ' ' + words[(countN % 100 > 4 && countN % 100 < 20) ? 2 : cases[Math.min(countN % 10, 5)]]
}

export default {
  name: 'purchase',
  components: {
    xButton,
    textInput
  },
  data () {
    return {
      phoneCodeError: '',
      moneyError: '',
      bonusError: '',
      clientData: {
        adminId: 0,
        isTypeCode: true,
        name: '',
        bonuses: 0,
        bonusesCanUse: 0,
        averageCheck: 0
      },
      step2: false,
      step3: false,
      step1: true,
      code: undefined,
      bonus: 0,
      money: undefined
    }
  },
  computed: {
    bonusNumberFormat () {
      return pluralize(
        this.clientData.bonuses,
        ['бонус', 'бонуса', 'бонусов'],
        this.formatSum(this.clientData.bonuses, true)
      )
    },
    bonusCanUseNumberFormat () {
      let bonusesCanUse = this.clientData.bonusesCanUse
      const money = this.money || 0
      if (money < bonusesCanUse) {
        bonusesCanUse = money
      }

      return pluralize(
        bonusesCanUse,
        ['бонус', 'бонуса', 'бонусов'],
        this.formatSum(bonusesCanUse, true)
      )
    },
    sumNumberFormat () {
      return this.formatSum(this.clientData.averageCheck)
    },
    purchaseCalc () {
      if (this.bonus === 0) {
        return this.money
      } else {
        const sum = (this.money ? this.money : 0) - this.bonus
        if (sum <= 0) {
          return 0
        } else {
          return sum
        }
      }
    }
  },
  methods: {
    spendAllBonuses () {
      if (this.clientData.isTypeCode) {
        this.bonus = this.clientData.bonusesCanUse
      }
    },
    formatSum (sum, isBonusNumber = false) {
      const formatted = (new Intl.NumberFormat('ru-RU').format(sum)).replace(',', '.').split('.')

      if (!isBonusNumber) {
        if (formatted.length === 1) {
          formatted.push('00')
        } else if (formatted[1].length === 1) {
          formatted[1] = formatted[1] + '0'
        }
      }

      return formatted.join('.')
    },
    codeValidating (event) {
      const value = event.target.value
      const splitVal = value.split('+')
      const isPhone = splitVal.length === 2
      const forTestValue = isPhone ? splitVal[1] : splitVal[0]
      if (/\D/gmu.test(forTestValue)) {
        const correctValue = forTestValue.replace(/\D/gmu, '')
        this.code = `${isPhone ? '+' : ''}${correctValue}`
      }
      this.phoneCodeError = ''
    },
    bonusValidating (event) {
      const value = event.target.value
      if (value > this.clientData.bonusesCanUse) {
        this.bonus = this.clientData.bonusesCanUse
      }
    },
    moneyValidating (event) {
      const value = event.target.value
      if (/\D/gmu.test(value)) {
        this.money = value.replace(/\D/gmu, '')
      }
      this.moneyError = ''
    },
    toggleStep2 () {
      if (this.code.length > 6 && this.code.length !== 12) {
        this.phoneCodeError = 'Введите корректный номер телефона'
      }
      if (this.code.replace(/\D/gmu, '').length < 6) {
        this.phoneCodeError = 'Введите не менее 6 цифр'
      }

      if (!this.phoneCodeError) {
        fetch(`${window.host}cashiers_lk/purchases/step1/?search=${this.code}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          }
        }).then(async response => {
          const data = await response.json()

          if (!response.ok || !data.success) {
            const error = (data && data.message) || response.status
            if (data && data.message) {
              this.$store.commit(
                'popupStore/show',
                { text: data.message }
              )
            }
            return Promise.reject(error)
          }

          const bonuses = data.data.bonus_number
          const canUsePercent = data.data.percentCanUse
          const canUseBonuses = parseInt(bonuses - (bonuses / 100 * canUsePercent))

          this.clientData.adminId = data.data.id
          this.clientData.isTypeCode = data.data.type === 'code'
          this.clientData.name = data.data.name
          this.clientData.bonuses = data.data.bonus_number
          this.clientData.bonusesCanUse = canUseBonuses
          this.clientData.averageCheck = data.data.average

          this.step2 = true
          this.step1 = false
        }).catch(err => {
          this.$store.commit('techStore/checkError', { err })
        })
      }
    },
    toggleStep3 () {
      if (!this.money) {
        this.moneyError = 'Укажите сумму к оплате'
      }

      if (!this.moneyError && !this.bonusError) {
        fetch(`${window.host}cashiers_lk/clients/${this.clientData.adminId}/purchases/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          },
          body: JSON.stringify({
            roubles: this.purchaseCalc,
            bonuses: this.bonus,
            sum: this.money
          })
        }).then(async response => {
          const data = await response.json()

          if (!response.ok || !data.success) {
            const error = (data && data.message) || response.status
            return Promise.reject(error)
          }

          this.step3 = true
          this.step2 = false
        }).catch(err => {
          this.$store.commit('techStore/checkError', { err })
        })
      }
    }
  },
  emits: ['titleName'],
  created () {
    this.$emit('titleName', 'Регистрация покупки')
  }
}
</script>

<style lang="scss" src="./purchaseCashier.scss" scoped/>
<style lang="scss" src="./../../components/textInput/textInput.scss" scoped/>
