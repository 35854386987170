import cashier from '@/views/cashier'
import purchaseCashier from '@/views/purchaseCashier'

// Tech
import error from '@/views/error'

// Account
import account from '@/views/account'
import accountCashierLogin from '@/views/accountCashierLogin'

export default [
  {
    path: '/cashier',
    redirect: '/cashier/purchase',
    component: cashier,
    children: [
      {
        path: 'purchase',
        component: purchaseCashier
      }
    ]
  },
  {
    path: '/',
    redirect: '/login',
    component: account,
    children: [
      {
        path: '/login',
        component: accountCashierLogin,
        name: 'login'
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: error
  }
]
