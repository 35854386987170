<template>
  <cashierSidebar />
  <div class="page_body_cashier">
    <div class="container">
      <router-view @titleName="handleName"/>
    </div>
  </div>
  <errorPopup/>
  <save-popup/>
</template>

<script>
import savePopup from '@/components/savePopup'
import cashierSidebar from '@/components/cashierSidebar'
import errorPopup from '@/components/errorPopup'

export default {
  name: 'admin',
  components: {
    cashierSidebar,
    savePopup,
    errorPopup
  },
  data () {
    return {
      titleOld: '',
      loading: false
    }
  },
  methods: {
    handleName (name) {
      if (name === this.titleOld) {
        this.loading = false
      } else {
        this.loading = true
        setTimeout(function (scope) {
          scope.loading = false
        }, 100, this)
      }
      this.titleOld = name
      this.$store.commit(
        'techStore/setTitleName',
        {
          name
        }
      )
    }
  },
  created () {
    fetch(window.host + 'cashiers/current', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      }
    }).then(async response => {
      const data = await response.json()

      if (!response.ok || !data.success) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      // valid cashier
    }).catch(err => {
      this.$store.commit('techStore/checkError', { err })
    })
  }
}
</script>

<style scoped src="./cashier.scss" lang="scss" />
